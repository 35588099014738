import retina from 'retinajs';
window.addEventListener('load', retina);

import 'jquery-match-height';
import Nav from './components/nav';
// import Carousel from './components/carousel';
import mapboxgl from 'mapbox-gl';
// import Form from './components/form';

if (!("ontouchstart" in document.documentElement)) {
document.documentElement.className += " no-touch";
}

new Nav();
// new Carousel();
// const form = new Form();

var myMap = document.getElementById("map");
if(myMap){
    mapboxgl.accessToken = 'pk.eyJ1IjoiYmxhbWVqZW4iLCJhIjoiY2s5b3RkZGtxMDQ2NzNrcG5zanp2emRidyJ9.UiuTlRJiXaiVnCtpC67tCQ';

    var map = new mapboxgl.Map({
    container: 'map',
    style: 'mapbox://styles/blamejen/cla8p49gn000014pf35538i3d',
    center: [174.7678665, -36.8483507],
    zoom: 16
    });
    map.scrollZoom.disable();
    map.addControl(new mapboxgl.NavigationControl());

    // Create a DOM element for each marker.
    const el = document.createElement('div');
    const width = 43;
    const height = 40;
    el.className = 'marker';
    el.style.backgroundImage = `url(https://faceclub.co.nz/img/logo-faceclub.png)`;
    el.style.width = `${width}px`;
    el.style.height = `${height}px`;
    el.style.backgroundSize = '100%';

    // Add markers to the map.
    new mapboxgl.Marker(el)
    .setLngLat([174.7678665, -36.8483507])
    .addTo(map);
}






