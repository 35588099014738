import 'mmenu-js';

export default class Nav {
  constructor() {

    document.addEventListener(
      "DOMContentLoaded", () => {
        new Mmenu("#faceclub-menu", {
          scrollBugFix: {
            fix: true
          },
          pageScroll: {
            "scroll": true,
            "update": true
          },
          "extensions": [
            "border-offset",
            "pagedim-black",
            "theme-dark",
            "multiline",
            "position-bottom"
          ]
        }, {
          pageScroll: {
            scrollOffset: 60
          }
        });
        function sticky_relocate_2() {
          var window_top = $(window).scrollTop();
          var div_top = $('main').offset().top - 100;
          if (window_top > div_top) {
            $('.faceclub-brand').addClass('pinned')
          }
          if(window_top > $('main').position().top){
            $('.faceclub-brand').removeClass('pinned');
          }
        }
        $(window).scroll(sticky_relocate_2);
      }
    );
  }
}


